import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import { Box, List, ListItem, ListIcon, Text, Wrap, WrapItem, Center, Button } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'

export interface IPlanBreakdownProps {
  showOrderBtn?: boolean
  bordered?: boolean
}

const PlanBreakdown = ({ showOrderBtn = true, bordered = false }: IPlanBreakdownProps): JSX.Element => {
  return (
    <Box
      bg="whitesmoke"
      p={6}
      mt={4}
      as="article"
      minW="65%"
      mx="auto"
      w="max-content"
      maxW="100%"
      borderWidth={bordered && 4}
      borderColor={bordered && 'pink.500'}
    >
      <List spacing={3}>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Unlimited data within our coverage area.
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Use any existing SIM for any mobile network.
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          No calls or texts, because it's not 2006 anymore.
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          No speed restrictions or fair use policies.
        </ListItem>
        <ListItem>
          <ListIcon as={CheckCircleIcon} color="green.500" />
          Cancel any time.
        </ListItem>
      </List>

      <Wrap align="baseline" justify="center" marginTop={2} spacing={1}>
        <WrapItem>
          <Text fontSize="lg" fontWeight="bold">
            starting from{' '}
          </Text>
        </WrapItem>
        <WrapItem>
          <Text fontSize="4xl" fontWeight="bold">
            £0
          </Text>
        </WrapItem>
      </Wrap>

      {showOrderBtn && (
        <Center marginTop={2}>
          <Button as={GatsbyLink} to="/order" colorScheme="pink">
            Order plan
          </Button>
        </Center>
      )}
    </Box>
  )
}

export { PlanBreakdown }
